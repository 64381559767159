<template>
  <v-container>
    <v-row class="no-print">
      <v-col cols="9">
        <h3>{{ $t('Chef.promo_share_title') }}</h3>
        <h5>{{ $t('Chef.promo_share_sub_title') }}</h5>
      </v-col>
      <v-col cols="1">
        <v-menu transition="scale-transition" origin="right top" bottom left>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" icon v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="app-card-action">
            <v-list-item @click.stop="deletePromo" :disabled="hasRedeem">
              <v-icon color="red" class="mr-3">remove</v-icon>
              <span>{{ $t('Common.delete') }}</span>
            </v-list-item>
            <!-- <v-list-item :to="'/coupons/share/' + id">
              <v-icon color="cyan" class="mr-3">share</v-icon>
              <span>{{ $t('Common.share') }}</span>
            </v-list-item>-->
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="title"
          :hint="`For example: Summer Promotion from ${profile.businessName}`"
          :placeholder="`Promotion from ${profile.businessName}`"
          label="Promotion Title"
          counter
          maxlength="25"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col cols="6" md="4">
        Type:
        {{ promo_type ? promo_type.value : '' }}
      </v-col>
      <v-col cols="6" md="4">
        Amount:
        {{ amount_type === '%' ? amount + '%' : amount }}
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col cols="12" sm="5" lg="5">
        <v-textarea label="Detail" v-model="detail" placeholder></v-textarea>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col cols="12" sm="8">
        <v-btn :disabled="!valid" small color="primary" @click.prevent="save"
          >Save</v-btn
        >
        <v-btn
          small
          color="success"
          class="mx-1"
          @click="setPublishType('email')"
          >Email</v-btn
        >
        <v-btn
          color="success"
          class="mx-1"
          small
          @click="setPublishType('push')"
          >Push Notification</v-btn
        >

        <v-btn
          small
          color="success"
          class="mx-1"
          @click="setPublishType('print')"
          >Print</v-btn
        >
        <v-btn small to="/coupons/list" class="mx-2">cancel</v-btn>

        <v-menu open-on-hover top offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" text id="dishShare" v-on="on">
              <v-icon>share</v-icon>
            </v-btn>
          </template>
          <v-list>
            <vue-goodshare-facebook
              data-size="large"
              title_social="Facebook"
              has_counter
              :page_image="this.user.photoUrl"
              :page_url="promoLink"
              has_icon
            ></vue-goodshare-facebook>

            <vue-goodshare-twitter
              title_social="Twitter"
              :page_url="promoLink"
              :page_image="this.user.photoUrl"
              :page_title="title"
              has_counter
              has_icon
            ></vue-goodshare-twitter>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <template v-if="publish">
      <v-row class="no-print">
        <v-col
          cols="12"
          sm="8"
          md="5"
          v-if="publish === 'push' || publish === 'email'"
        >
          <!-- email share publish -->
          <v-divider></v-divider>
          <v-col cols="12" v-if="radius">
            <p class="body-2">
              This promotion will be sent to customers within {{ radius }} miles
            </p>
          </v-col>

          <v-row align="center">
            <v-col cols="12" sm="8" lg="6">
              <v-checkbox
                v-model="sendToAllFollowers"
                label="Send To All Followers"
                @change="addEmails"
                color="primary"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="8" lg="6">
              <v-checkbox
                v-model="sendToAllCustomers"
                label="Send To All Customers"
                @change="addEmails"
                color="primary"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="publish === 'email'">
              <p class="body-2">{{ $t('Chef.promo_email_title') }}</p>
            </v-col>
            <v-col cols="12" v-if="publish === 'email'">
              <v-combobox
                v-model="emails"
                :items="suggestionEmails"
                label="Emails"
                :delimiters="[' ']"
                persistent-hint
                multiple
                small-chips
                deletable-chips
                hide-no-data
                hide-details
                :menu-props="{ offsetY: true }"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-col cols="12" class="mt-6">
            <v-btn
              large
              color="info"
              type="submit"
              @click.prevent="sendSharePromotion"
              :disabled="validToSend || emailSuccess"
            >
              Send
              <v-icon right>mdi-send</v-icon>
            </v-btn>
            <v-alert
              v-if="emailSuccess"
              type="success"
              outlined
              :value="true"
              class="mt-3"
              >Promotion publication scheduled!</v-alert
            >
          </v-col>

          <!-- social media share -->
        </v-col>
        <v-col
          cols="12"
          offset-sm="1"
          sm="6"
          v-if="pushNotification && publish === 'push'"
        >
          <v-img src="/img/android.png">
            <v-container class="fill-height " fluid>
              <v-row align="center" class="mx-6 py-2 white">
                <v-sheet outlined height="50" class="white">
                  <v-col cols="12" class="title"> {{ title }}</v-col>
                  <v-col cols="12"> {{ detail }}</v-col>
                </v-sheet>
              </v-row>
            </v-container>
          </v-img>
        </v-col>
      </v-row>
      <v-divider class="no-print"></v-divider>
      <v-row v-if="publish === 'print'">
        <v-col cols="12" sm="6" v-if="coupons && coupons.length > 0">
          <v-container v-for="(c, i) in coupons" :key="i">
            <view-coupon
              :promotion="promotion"
              :detail="detail"
              :coupon_id="c.id"
              :promotion_id="id"
              :producer_id="user.id"
              :card_logo="cardLogo"
              :card_size="cardSize"
            ></view-coupon>
          </v-container>
        </v-col>
        <v-col cols="12" sm="6" v-else>
          <view-coupon
            :promotion="promotion"
            :detail="detail"
            :promotion_id="id"
            :producer_id="user.id"
            :card_logo="cardLogo"
            :card_size="cardSize"
          ></view-coupon>
        </v-col>
        <v-col cols="12" sm="6" class="no-print" v-if="publish === 'print'">
          <v-row justify="end">
            <v-col cols="6" sm="8">
              <v-select
                v-model="cardSize"
                :items="cardSizeOptions"
                label="Card Sizes in Inches"
              ></v-select>

              <v-btn
                :disabled="!uploadImage"
                color="primary"
                small
                class="white--text mr-1"
                @click="pickBusinessLogo()"
                >Use Business Logo</v-btn
              >

              <v-btn
                :disabled="uploadImage"
                small
                color="primary"
                class="white--text"
                @click="$refs.fileInput.click()"
              >
                <v-icon>file_upload</v-icon>Upload Image
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <input
        type="file"
        style="display:none"
        ref="fileInput"
        @change="onFileUpload"
        accept="image/*"
      />
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodsharePinterest from 'vue-goodshare/src/providers/Pinterest.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'

export default {
  components: {
    VueGoodshareFacebook,
    VueGoodsharePinterest,
    VueGoodshareTwitter,
    ViewCoupon: () =>
      import('fod-core/src/components/promotions/ViewCoupon.vue')
  },
  data() {
    return {
      promotion: null,
      title: null,
      detail: null,
      id: null,
      emails: [],
      amount: null,
      coupons: null,
      amount_type: null,
      promo_type: null,
      now: null,
      emailSuccess: false,
      sendToAllFollowers: false,

      cardSizeOptions: ['3.5 x 2', '6 x 4', '5.5 x 4.2', '7 x 5'],
      cardSize: '3.5 x 2',
      cardLogo: null,
      uploadImage: false,
      pushNotification: false,
      sendToAllCustomers: false,
      publish: null,
      radius: null,
      rules: {
        checkAmount: value => {
          if (value) {
            if (value <= 0) {
              return 'Wrong value'
            }
            if (this.amount_type === '%') {
              if (value > 100) {
                return 'Wrong value'
              }
            }
          }
          return true
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      user: 'user',
      followers: 'followers',
      previousCustomers: 'previousCustomers',
      profile: 'profile'
    }),
    valid() {
      return this.title && this.title.length <= 25 && this.amount
    },
    validToPublish() {
      console.log('hasError: ', this.hasError)
      let errs = Object.keys(this.errors).length
      return (
        this.title &&
        this.amount &&
        errs < 1 &&
        this.list &&
        this.list.length > 0
      )
    },
    minDate() {
      const date = new Date()
      date.setDate(date.getDate() - 1)
      return date.toISOString().substr(0, 10)
    },
    hasRedeem() {
      let found = false
      if (this.coupons) {
        this.coupons.forEach(coupon => {
          if (coupon.redeem_by && coupon.redeem_by.length > 0) {
            found = true
            return
          }
        })
      }
      return found
    },
    promoLink() {
      const domain = process.env.VUE_APP_DOMAIN
      return `https://${domain}/producer/${this.profile.handle}?c=${this.title}`
    },
    getDetail() {
      if (this.detail) {
        var length = 100
        var truncDetail = this.detail.substring(0, length)
        truncDetail += '... @takeinlocal'
        return truncDetail
      }
    },
    suggestionEmails() {
      // convert follower and customer arrays to a set so that emails do not repeat
      const followerSet = new Set(this.followers.map(a => a.email))
      const customerSet = new Set(this.previousCustomers)
      return Array.from(new Set([...followerSet, ...customerSet]))
    },
    validToSend() {
      if (this.sendToAllCustomers || this.sendToAllFollowers) {
        return false
      }

      if (!this.emails || this.emails.length == 0) {
        return true
      }
      for (let email of this.emails) {
        email = email.toLowerCase().trim()
        if (this.inputIsValid(email)) {
          // check if user added their own email
          if (email === this.user.email) {
            return true
          }
          return false
        } else {
          return true
        }
      }
      return false
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.$store
        .dispatch('getPromotion', { id: this.$route.params.id })
        .then(data => {
          if (data) {
            this.promotion = data
            this.title = data.title
            this.amount = data.amount
            this.detail = data.detail
            this.amount_type = data.amount_type
            this.promo_type = data.promo_type
            this.radius = data.radius

            this.$store
              .dispatch('getCoupons', { load: false, id: this.id })
              .then(list => {
                this.coupons = list
              })
          }
        })
    }

    if (!this.followers || this.followers.length == 0) {
      this.$store.dispatch('loadFollowers', { uid: this.user.id })
    }
    if (!this.previousCustomers || this.previousCustomers.length == 0) {
      this.$store.dispatch('loadPreviousCustomers', { uid: this.user.id })
    }

    if (this.profile) {
      if (this.profile.businessLogo) {
        this.cardLogo = this.profile.businessLogo
      } else {
        this.cardLogo = this.profile.avatar
      }
    }
  },
  methods: {
    save() {
      this.$store
        .dispatch('savePromotion', {
          id: this.id,
          title: this.title || null,
          detail: this.detail || null
        })
        .then(() => {
          this.$store.dispatch('setMessage', { title: '', body: 'Saved' })
        })
    },
    print() {},
    checkCoupon(code) {
      console.log('Checking ', code)
      this.$store.dispatch('getCoupon', { code: code }).then(c => {
        console.log('Result ', c)

        if (c) {
          this.hasError = true
          this.errors[code] = `Code exists  ${code}`
        } else {
          //delete this.errors[code]
          //console.log('11111111111111 ', Object.keys(this.errors))
          this.errors = {}
          this.hasError = false
        }
      })
    },
    // checks if email in text filled is legitimate
    inputIsValid(email) {
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!regex.test(email)) {
        return false
      }
      return true
    },
    sendPromo(recipients) {
      this.$store
        .dispatch('sendPromo', {
          uid: this.user.id,
          recipients: recipients,
          coupon: this.promotion,
          sender: this.user.email,
          sendToAllFollowers: this.sendToAllFollowers,
          sendToAllCustomers: this.sendToAllCustomers,
          radius: this.radius,
          // pushNotification: this.pushNotification,
          publish: this.publish,
          status: 'new'
        })
        .then(res => {
          console.log(res, 'coupon response.')
        })
    },
    // validate emails before sending

    sendSharePromotion() {
      this.emailSuccess = true
      let recipients = []
      for (let email of this.emails) {
        email = email.toLowerCase().trim()
        if (!recipients.includes(email)) {
          recipients.push(email)
        }
      }
      this.sendPromo(recipients)
    },
    addEmails() {
      // populates text box with customers who have previously ordered from this producer
      if (this.sendToAllCustomers && this.sendToAllFollowers) {
        if (this.publish === 'email') {
          this.emails = this.suggestionEmails
        }
      } else if (this.sendToAllFollowers) {
        if (this.publish === 'email') {
          this.emails = this.followers.map(a => a.email)
        }
      } else if (this.sendToAllCustomers) {
        if (this.publish === 'email') {
          this.emails = this.previousCustomers
        }
      } else {
        this.emails = []
      }
    },
    onFileUpload(event) {
      const files = event.target.files
      let filename = files[0].name
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Not a valid image')
      }
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.uploadImage = true
        this.cardLogo = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
    },
    pickBusinessLogo() {
      this.cardLogo = this.profile.businessLogo
      this.uploadImage = false
    },
    setPublishType(publish) {
      this.publish = publish
      this.emailSuccess = false
      if (publish === 'push') {
        this.pushNotification = true
      }
    }
  }
}
</script>

<style>
.fsx-12 .v-label {
  font-size: 0.8em;
}
</style>
